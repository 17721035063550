export const batteryLevels = [
  {
    interval: [12.9, Infinity],
    level: 100,
    icon: "fa fa-battery-full",
    color: "green",
  },
  {
    interval: [12.35, 12.9],
    level: 75,
    icon: "fa fa-battery-three-quarters",
    color: "green",
  },
  {
    interval: [12.05, 12.35],
    level: 50,
    icon: "fa fa-battery-half",
    color: "orange",
  },
  {
    interval: [11.7, 12.05],
    level: 25,
    icon: "fa fa-battery-quarter",
    color: "red",
  },
  {
    interval: [0, 11.7],
    level: 0,
    icon: "fa fa-battery-empty",
    color: "red",
  },
]
