
import Highcharts from "highcharts"
import Vue, { PropType } from "vue"

export type ChartUpdateArgs = [
  boolean,
  boolean,
  Highcharts.AnimationOptionsObject
]

export default Vue.extend({
  name: "VueHighcharts",
  props: {
    highcharts: {
      type: Object as PropType<typeof Highcharts>,
      default: null,
    },
    options: {
      type: Object as PropType<Highcharts.Options>,
      default: null,
    },
    constructorType: {
      type: String,
      default: "chart",
    },
    callback: {
      type: Function as PropType<Highcharts.ChartCallbackFunction>,
      default: null,
    },
    deepCopyOnUpdate: {
      type: Boolean,
      default: true,
    },
    updateArgs: {
      type: Array as PropType<Array<ChartUpdateArgs>>,
      default: () => [],
    },
  },
  data() {
    return {
      chartContainer: null,
      chart: null,
    }
  },
  watch: {
    options: {
      handler(options) {
        this.chart?.update(
          this.copyObject(options, this.deepCopyOnUpdate),
          ...this.updateArgs
        )
      },
      deep: true,
    },
  },
  mounted() {
    this.chartContainer = this.$refs.chartContainer
    this.chart = null
    this.$nextTick(() => {
      let HC = this.highcharts || Highcharts

      if (this.options && HC[this.constructorType]) {
        this.chart = HC[this.constructorType](
          this.chartContainer,
          this.copyObject(this.options, true),
          this.callback ? this.callback : null
        )
      } else if (!this.options) {
        console.warn('The "options" parameter was not passed.')
      } else {
        console.warn(
          `'${this.constructorType}' constructor-type is incorrect. Sometimes this error is caused by the fact that the corresponding module wasn't imported.`
        )
      }
    })
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  methods: {
    doCopy(copy, original, copyArray) {
      const callback = (value, key) => {
        if (
          Highcharts.isObject(value, !copyArray) &&
          !Highcharts.isClass(value) &&
          !Highcharts.isDOMElement(value)
        ) {
          copy[key] = this.doCopy(
            copy[key] || (Highcharts.isArray(value) ? [] : {}),
            value,
            copyArray
          )
        } else {
          copy[key] = original[key]
        }
      }

      if (Highcharts.isArray(original)) {
        original.forEach(callback)
      } else {
        Highcharts.objectEach(original, callback)
      }

      return copy
    },
    copyObject(obj, copyArray) {
      return this.doCopy({}, obj, copyArray)
    },
  },
})
