
import Vue, { type PropType } from "vue"
import type {
  ChatMessage,
  ChatMessageAnnotation,
  ChatSuggestion,
  EMarkdownRegex,
} from "@evercam/ui"
import { ChatMessageRole, ChatMessageType } from "@evercam/ui"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  type Camera,
  type CameraExid,
  CameraFeatureFlag,
  type CamerasByExid,
  type CopilotCamera,
  CopilotChatProvider,
  type CopilotConversationContext,
  type CopilotMessageContext,
  CopilotMessageType,
  type CopilotMissingFields,
  CopilotMissingFieldsLabels,
  type CopilotProject,
  CopilotSocketEvent,
  CopilotSuggestion,
  type CopilotSystemToolCallResponse,
  type CopilotTimelapse,
  CopilotToolId,
  FeedbackContext,
  type FeedbackPayload,
  type Project,
  type ProjectExid,
  ProjectFeatureFlag,
  type ProjectsByExid,
  type User,
} from "@evercam/shared/types"
import { io, Socket } from "socket.io-client"
import { shuffleArray } from "@evercam/shared/utils"
import {
  EvercamLabsApi,
  getLabsBaseUrl,
} from "@evercam/shared/api/evercamLabsApi"
import CopilotHeaderActions from "@evercam/shared/components/copilot/CopilotHeaderActions.vue"
import CopilotAnnotation from "@evercam/shared/components/copilot/CopilotAnnotation"
import CopilotAnprThumbnail from "@evercam/shared/components/copilot/CopilotAnprThumbnail"
import BetaTag from "@evercam/shared/components/BetaTag"
import CopilotMissingFieldsForm from "@evercam/shared/components/copilot/CopilotMissingFieldsForm.vue"
import CopilotToolCallResult from "@evercam/shared/components/copilot/CopilotToolCallResult.vue"

export default Vue.extend({
  name: "CopilotChat",
  components: {
    BetaTag,
    CopilotAnprThumbnail,
    CopilotAnnotation,
    CopilotMissingFieldsForm,
    CopilotHeaderActions,
    CopilotToolCallResult,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    token: {
      type: String,
      default: "",
    },
    selectedCamera: {
      type: [Object, undefined] as PropType<Camera | undefined>,
      default: undefined,
    },
    selectedProject: {
      type: [Object, undefined] as PropType<Project | undefined>,
      default: undefined,
    },
    selectedTimelapse: {
      type: [Object, undefined] as PropType<CopilotTimelapse | undefined>,
      default: undefined,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    provider: {
      type: String as PropType<CopilotChatProvider>,
      default: () => CopilotChatProvider.ChatGpt,
    },
    timelapse: {
      type: [Object, undefined] as PropType<CopilotTimelapse | undefined>,
      default: undefined,
    },
    eMarkdownProps: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
    pageId: {
      type: String as PropType<AnalyticsEventPageId>,
      default: "",
    },
  },
  data() {
    return {
      socket: null as unknown as Socket,
      activeIndex: 0,
      messages: [] as ChatMessage[],
      userMessage: "",
      isShiftKeyPressed: false,
      queuedChunk: "",
      loadingMessage: "" as string | string[],
      remoteMessageIds: {} as Record<number, number>,
      conversationId: 0,
      isAwaitingMessage: false,
      isReceivingMessage: false,
      annotations: {} as Record<string, { url: string; index: number }>,
      isInitialized: false,
      missingFields: {} as CopilotMissingFields,
      missingFieldsLabels: CopilotMissingFieldsLabels,
      ChatMessageType,
    }
  },
  computed: {
    CopilotMessageType() {
      return CopilotMessageType
    },
    timezone(): string {
      return (
        this.selectedProject?.timezone ??
        Object.values(this.projectsByExid)[0]?.timezone ??
        "Europe/Dublin"
      )
    },
    conversationContext(): CopilotConversationContext {
      const camerasByProjectExid = Object.values(this.camerasByExid).reduce(
        (acc, c) => {
          const camera = {
            name: c.name,
            exid: c.exid,
            status: c.status,
          } as CopilotCamera

          if (c.featureFlags?.length) {
            camera.featureFlags = c.featureFlags
          }
          if (!acc[c.project.id]) {
            acc[c.project.id] = []
          }
          acc[c.project.id].push(camera)

          return acc
        },
        {} as Record<ProjectExid, CopilotCamera[]>
      )

      const availableProjects = Object.values(this.projectsByExid).map((p) => {
        const project = {
          name: p.name,
          exid: p.exid,
          status: p.status,
          cameras: camerasByProjectExid[p.exid],
        } as CopilotProject

        if (p.featureFlags.length) {
          project.featureFlags = p.featureFlags
        }

        return project
      }) as CopilotProject[]

      return {
        availableProjects,
        timelapse: this.timelapse,
      }
    },
    messageContext(): CopilotMessageContext {
      let selectedCamera: CopilotCamera | undefined
      if (this.selectedCamera) {
        selectedCamera = {
          name: this.selectedCamera.name,
          exid: this.selectedCamera.exid,
          status: this.selectedCamera.status,
          featureFlags: this.selectedCamera.featureFlags,
        }
      }

      let selectedProject: CopilotProject | undefined
      if (this.selectedProject) {
        selectedProject = {
          name: this.selectedProject.name,
          exid: this.selectedProject.exid,
          status: this.selectedProject.status!,
          featureFlags: this.selectedProject.featureFlags,
        }
      }

      return {
        pageId: this.pageId as AnalyticsEventPageId,
        selectedCamera,
        selectedProject,
      }
    },
    lastMessage(): ChatMessage {
      return this.messages.slice(-1)[0]
    },
    isCopilotTurn(): boolean {
      return this.lastMessage.role === ChatMessageRole.Copilot
    },
    hasGateReport(): boolean {
      if (this.selectedProject) {
        return !!this.selectedProject?.featureFlags.includes(
          ProjectFeatureFlag.GateReport
        )
      }

      return !!Object.values(this.projectsByExid).find((p) =>
        p.featureFlags.includes(ProjectFeatureFlag.GateReport)
      )
    },
    isReadonly(): boolean {
      return (
        this.provider === CopilotChatProvider.GeminiTimelapse && !this.timelapse
      )
    },
    hasTimelapseReports(): boolean {
      return !!this.selectedProject?.cameras?.some((c) =>
        c.featureFlags.includes(CameraFeatureFlag.CopilotTimelapseReport)
      )
    },
    projectExidRegex(): RegExp {
      const exids = Object.keys(this.projectsByExid)

      return new RegExp(`(?<!/)(${exids.join("|")})(?!/)`, "gmi")
    },
    cameraExidRegex(): RegExp {
      const exids = Object.keys(this.camerasByExid)

      return new RegExp(`(?<!/)(${exids.join("|")})(?!/)`, "gmi")
    },
    exposedMarkdownRegexes(): EMarkdownRegex[] {
      let regexes = [
        {
          type: "timestamp",
          regex:
            /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2}))|(\$\$(?:.*?)\$\$)/gm,
        },
        {
          type: "cameraExid",
          regex: this.cameraExidRegex,
        },
        {
          type: "projectExid",
          regex: this.projectExidRegex,
        },
        {
          type: "annotation",
          regex: /(【.*†source】)/gm,
        },
        {
          type: "anprThumbnail",
          regex: /(grt_.*_grt)/gm,
        },
      ]

      if (this.timelapse) {
        regexes.push({
          type: "videoTimestamp",
          regex: /[^a-zA-Z0-9]?((?:\d{1,2}:)?\d{2}:\d{2})[^a-zA-Z0-9]?/gm,
        })
      }

      return regexes
    },
    suggestions(): ChatSuggestion[] {
      if (this.provider === CopilotChatProvider.GeminiTimelapse) {
        return []
      }

      const getRandomIndex = (number = 5) => Math.floor(Math.random() * number)
      let suggestions = [
        {
          type: CopilotSuggestion.Weather,
          text: this.$t(`copilot.suggestions.weather_${getRandomIndex()}`),
          icon: "fas fa-cloud-sun",
          color: this.suggestionsCardIconColor,
        },
      ]

      if (this.hasGateReport) {
        suggestions.push({
          type: CopilotSuggestion.GateReport,
          text: this.$t(`copilot.suggestions.gate_report_${getRandomIndex()}`),
          icon: "fas fa-truck",
          color: this.suggestionsCardIconColor,
        })
      }

      if (this.hasTimelapseReports) {
        suggestions.push({
          type: CopilotSuggestion.SiteActivity,
          text: this.$t(
            `copilot.suggestions.site_activity_${getRandomIndex()}`
          ),
          icon: "fas fa-chart-line",
          color: this.suggestionsCardIconColor,
        })
      }

      for (let i = 0; i <= 3 - suggestions.length; i++) {
        suggestions.push({
          type: CopilotSuggestion.UserManual,
          text: this.$t(`copilot.suggestions.user_manual_${getRandomIndex()}`),
          icon: "far fa-circle-question",
          color: this.suggestionsCardIconColor,
        })
      }

      suggestions.push({
        type: CopilotSuggestion.Clip,
        text: this.$t("copilot.suggestions.clip"),
        icon: "fas fa-photo-film",
        color: this.suggestionsCardIconColor,
      })
      suggestions.push({
        type: CopilotSuggestion.Timelapse,
        text: this.$t(`copilot.suggestions.timelapse_${getRandomIndex(4)}`),
        icon: "fas fa-history",
        color: this.suggestionsCardIconColor,
      })

      return shuffleArray(suggestions)
    },
    suggestionsCardIconColor(): string {
      return this.$vuetify.theme.dark ? "#64748b" : "#94a3b8"
    },
  },
  watch: {
    timelapse() {
      if (this.conversationId) {
        this.startNewConversation()
      }
    },
  },
  mounted() {
    this.initSocket()
    this.focusInput()
  },
  methods: {
    initSocket() {
      this.socket = io(getLabsBaseUrl() as string, {
        path: "/ws/copilot",
        transports: ["websocket"],
        query: {
          userEmail: this.user.email,
        },
        auth: {
          token: this.token,
        },
      })

      this.socket
        .on(CopilotSocketEvent.LLMMessageLoading, this.onLLMMessageLoading)
        .on(CopilotSocketEvent.LLMMessageChunk, this.onLLMMessageChunk)
        .on(CopilotSocketEvent.LLMMessageComplete, this.onLLMMessageComplete)
        .on(CopilotSocketEvent.LLMRequestCanceled, this.onRequestCanceled)
        .on(CopilotSocketEvent.MissingFields, this.onMissingFields)
        .on(
          CopilotSocketEvent.SystemToolCallResponse,
          this.onSystemToolCallResponse
        )
        .on(CopilotSocketEvent.ChatError, this.onChatError)
    },
    async startConversation() {
      return new Promise((resolve) => {
        this.socket
          .emit(
            CopilotSocketEvent.ConversationStart,
            this.conversationContext,
            this.provider
          )
          .on(
            CopilotSocketEvent.ConversationCreated,
            (conversationId: number) => {
              this.conversationId = conversationId
              resolve(conversationId)
            }
          )
      })
    },
    async startNewConversation() {
      this.isInitialized = false
      this.conversationId = 0
      this.socket.disconnect()
      this.socket.connect()
      await this.startConversation()
    },
    async onUserMessage() {
      if (!this.isInitialized) {
        await this.startConversation()
        this.isInitialized = true
      }
      if (this.isShiftKeyPressed) {
        return
      }
      if (!this.userMessage.trim()) {
        return
      }
      if (
        this.messages[this.messages.length - 1]?.type === ChatMessageType.Error
      ) {
        this.messages.pop()
      }
      this.$analytics.saveEvent(AnalyticsEvent.CopilotSendMessage)
      this.socket.emit(
        CopilotSocketEvent.UserMessage,
        this.userMessage,
        this.messageContext
      )
      this.isAwaitingMessage = true
      this.messages.push({
        id: Date.now(),
        content: this.userMessage,
        role: ChatMessageRole.User,
        timestamp: new Date().toISOString(),
      })
      this.userMessage = ""
    },
    onChatError() {
      let errorMessage = {
        content:
          "Something went wrong. If the issue persists, please contact support.",
        role: ChatMessageRole.Copilot,
        type: ChatMessageType.Error,
      }

      if (this.isCopilotTurn) {
        const lastMessage = this.messages[this.messages.length - 1]
        this.$set(this.messages, this.messages.length - 1, {
          ...lastMessage,
          ...errorMessage,
        })
      } else {
        this.messages.push({ ...errorMessage, id: new Date().getTime() })
      }
      this.$analytics.saveEvent(AnalyticsEvent.CopilotError)
      this.isReceivingMessage = false
      this.isAwaitingMessage = false
      this.loadingMessage = ""
    },
    onLLMMessageLoading(message: string) {
      this.isAwaitingMessage = true
      this.loadingMessage = message

      return
    },
    onLLMMessageChunk(chunk: string, annotations?: ChatMessageAnnotation[]) {
      this.isReceivingMessage = true
      this.isAwaitingMessage = false
      this.loadingMessage = ""

      if (!this.isCopilotTurn) {
        this.activeIndex = 0
        this.messages.push({
          id: Date.now(),
          content: [],
          role: ChatMessageRole.Copilot,
          type: ChatMessageType.Text,
        })
      }

      this.queuedChunk += chunk
      this.renderQueuedChunk()

      if (annotations) {
        this.updateAnnotations(annotations)
      }
    },
    onLLMMessageComplete(remoteId: number, _message: string) {
      this.renderQueuedChunk()
      this.remoteMessageIds[this.lastMessage.id as number] = remoteId
      this.isReceivingMessage = false
    },
    renderQueuedChunk() {
      if (
        this.messages[this.messages.length - 1]?.role === ChatMessageRole.User
      ) {
        return
      }
      if (Array.isArray(this.messages[this.messages.length - 1]?.content)) {
        this.$set(this.messages[this.messages.length - 1], "content", [
          ...this.messages[this.messages.length - 1].content,
          this.queuedChunk,
        ])
      } else {
        this.$set(this.messages[this.messages.length - 1], "content", [
          this.messages[this.messages.length - 1].content,
          this.queuedChunk,
        ])
      }
      this.queuedChunk = ""
    },
    onPromptChanged(prompt: string) {
      this.userMessage = prompt
    },
    onSuggestionClicked(suggestion: ChatSuggestion) {
      this.userMessage = suggestion.text
      this.onUserMessage()
    },
    onCancelRequest() {
      this.socket.emit(CopilotSocketEvent.LLMRequestCancel)
    },
    onRequestCanceled() {
      this.isAwaitingMessage = false
      this.loadingMessage = ""
    },
    onMessageRegenerate(messageId: number) {
      const promptIndex = this.messages.findIndex((m) => m.id === messageId)
      const prompt = this.messages[promptIndex - 1]?.content
      this.$analytics.saveEvent(AnalyticsEvent.CopilotRegenerate)
      this.socket.emit(
        CopilotSocketEvent.UserMessage,
        prompt,
        this.messageContext
      )
      this.isAwaitingMessage = true
      this.messages.push({
        id: Date.now(),
        content: prompt,
        role: ChatMessageRole.User,
        timestamp: new Date().toISOString(),
      })
    },
    onMissingFields(data: { name: string; type: string; toolId: string }[]) {
      const newMessageId = Date.now()
      this.isReceivingMessage = false
      this.isAwaitingMessage = false
      this.loadingMessage = ""
      this.missingFields = {
        ...this.missingFields,
        [newMessageId]: data.map((error) => {
          let value = null
          if (error.type === "boolean") {
            value = false
          }

          return {
            name: error.name,
            value,
            toolId: error.toolId,
            type: error.type,
            label: this.$t(`copilot.labels.${error.name}`),
          }
        }),
      }
      this.messages.push({
        id: newMessageId,
        content: `Please provide the **${data
          .map((error) => this.$t(`copilot.labels.${error.name}`))
          .join(", ")}** </br>`,
        role: ChatMessageRole.Copilot,
        timestamp: new Date().toISOString(),
        withActions: false,
      })
    },
    onMissingFieldsCompleted({
      id,
      missingFields,
    }: {
      id: number
      missingFields: Record<string, string | null>
    }) {
      this.messages.pop()
      this.isAwaitingMessage = true
      this.socket.emit(CopilotSocketEvent.MissingFieldsCompleted, missingFields)
      this.$delete(this.missingFields, id)
    },
    onChatClicked(e: Event) {
      const targetElement = e.target as HTMLDivElement

      if (targetElement?.dataset?.timestamp) {
        this.$emit(
          "timestamp-clicked",
          targetElement.dataset.timestamp.replaceAll("$", "")
        )
      } else if (targetElement?.dataset?.videoTimestamp) {
        this.$emit(
          "video-timestamp-clicked",
          targetElement?.dataset?.videoTimestamp?.replaceAll(/-|\s/gm, "")
        )
      }
    },
    async onMessageFeedback(feedback: Partial<FeedbackPayload>) {
      try {
        await EvercamLabsApi.copilot.submitMessageFeedback({
          text: feedback.text!,
          type: feedback.type!,
          user: this.user.email,
          context: FeedbackContext.CopilotMessage,
          conversationId: this.conversationId,
          messageId: this.remoteMessageIds[feedback.messageId as number],
        })
      } catch (e) {
        console.error(e)
        this.$errorTracker.save(e)
      }
    },
    onSystemToolCallResponse(response: CopilotSystemToolCallResponse) {
      if (response.toolId === CopilotToolId.NavigateToPage) {
        this.handleCopilotNavigation(response)

        return
      }
      const newMessageId = Date.now()
      this.messages.push({
        id: newMessageId,
        data: response,
        content: "",
        type: ChatMessageType.Json,
        role: ChatMessageRole.Copilot,
        timestamp: new Date().toISOString(),
        withActions: false,
      })
      this.messages.push({
        id: Date.now(),
        content: [],
        role: ChatMessageRole.Copilot,
        type: ChatMessageType.Text,
      })
    },
    handleCopilotNavigation(response: CopilotSystemToolCallResponse) {
      const { routeFullPath } =
        (response?.output as { routeFullPath: string }) ?? {}
      if (!routeFullPath) {
        return
      }
      this.$analytics?.saveEvent(AnalyticsEvent.CopilotNavigation, {
        destination: routeFullPath,
      })

      this.$router.push(`/v2${routeFullPath}`)
    },
    focusInput() {
      this.$setTimeout(() => {
        const input = this.$el.querySelector("textarea") as HTMLTextAreaElement
        input?.focus()
      }, 500)
    },
    getFormattedTimestamp(timestamp: string) {
      return (
        this.$moment
          // @ts-expect-error: moment should have tz function
          .tz(timestamp.replaceAll("$", ""), this.timezone)
          .format("dddd DD-MMM-YYYY h:mmA")
      )
    },
    getFormattedCameraName(cameraExid: CameraExid) {
      return this.camerasByExid[cameraExid.toLowerCase()]?.name
    },
    getFormattedProjectName(projectExid: ProjectExid) {
      return this.projectsByExid[projectExid.toLowerCase()]?.name
    },
    getCameraUrl(cameraExid: CameraExid) {
      return `${window.location.origin}/v2/cameras/${cameraExid.toLowerCase()}`
    },
    getProjectUrl(projectExid: ProjectExid) {
      return `${
        window.location.origin
      }/v2/projects/${projectExid.toLowerCase()}`
    },
    updateAnnotations(annotations: ChatMessageAnnotation[]) {
      this.annotations = {
        ...this.annotations,
        ...annotations.reduce(
          (acc, a) => ({
            ...acc,
            [a.text]: {
              url: a.url,
              index: a.index + 1,
            },
          }),
          {}
        ),
      }
    },
  },
})
