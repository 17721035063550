
import {
  type Media,
  MediaStatus,
  MediaType,
  AnalyticsEvent,
  type Camera,
  type DateType,
} from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelizeKeys } from "humps"
import Vue, { type PropType } from "vue"
export default Vue.extend({
  name: "MediaClip",
  props: {
    media: {
      type: Object as PropType<Media>,
      required: true,
    },
    cameraName: {
      type: String,
      default: "",
    },
    selectedCamera: {
      type: Object as PropType<Camera>,
      default: () => ({}),
    },
    withLoadingAnimation: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    refreshTimeoutMinutes: {
      type: Number,
      default: 15,
    },
    refreshIntervalMs: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      clip: { ...this.media },
      skeletonHeight: 0,
      MediaStatus,
    }
  },
  computed: {
    routeProjectExid(): string {
      const params = camelizeKeys(this.$route.params) as {
        projectExid?: string
      }

      return params.projectExid || ""
    },
    pendingClip(): Media | null {
      return this.isPending ? this.clip : null
    },
    thumbnailUrl(): string | undefined {
      if (this.isPendingTimelapse || this.clip.status === MediaStatus.Pending) {
        return "/evercam_logo.png"
      }

      return this.$imgproxy.get360pResizedImageUrl(this.clip?.thumbnailUrl)
    },
    isPending(): boolean {
      return [
        MediaStatus.Pending,
        MediaStatus.Creating,
        MediaStatus.Processing,
      ].includes(this.clip.status as MediaStatus)
    },
    clipIcon(): string {
      if (this.clip.type === MediaType.ExternalUrl) {
        return this.externalUrlIcon
      }

      const defaultIcon = this.$device.isIos ? "fas fa-clock" : "fas fa-history"

      const iconMap: Record<string, string> = {
        [MediaType.Compare]: this.$device.isIos
          ? "fas fa-compress"
          : "fas fa-compress-arrows-alt",
        [MediaType.Clip]: "fas fa-cloud",
        [MediaType.LocalClip]: "fas fa-video",
        [MediaType.XRay]: "fas fa-x-ray",
        [MediaType.EditedImage]: "fas fa-images",
        [MediaType.File]: "fas fa-upload",
      }

      return iconMap?.[this.clip.type] || defaultIcon
    },
    externalUrlIcon(): string {
      if (/youtube\.com/.test(this.clip.url!)) {
        return "fab fa-youtube"
      }
      if (/vimeo\.com/.test(this.clip.url!)) {
        return "fab fa-vimeo"
      }

      return "fas fa-link"
    },
    fileExtension(): string {
      return this.clip.fileName?.split(".").pop() || ""
    },
    isDocFile(): boolean {
      return [
        "pdf",
        "doc",
        "docx",
        "csv",
        "ppt",
        "pptx",
        "xls",
        "xlsx",
        "txt",
      ].includes(this.fileExtension)
    },
    mediaFileIcon(): string {
      const fileIcons: Record<string, string> = {
        pdf: "fa-file-pdf",
        doc: "fa-file-word",
        docx: "fa-file-word",
        csv: "fa-file-csv",
        ppt: "fa-file-powerpoint",
      }
      const ext = this.fileExtension

      return fileIcons[ext] || "fa-file"
    },
    isPendingTimelapse(): boolean {
      return (
        this.clip.type === MediaType.Timelapse &&
        this.clip.status !== MediaStatus.Completed
      )
    },
    isUrlOrEditedImage(): boolean {
      return [MediaType.EditedImage, MediaType.ExternalUrl].includes(
        this.clip.type
      )
    },
    mediaLink(): string | null {
      const mediaUrl = `/v2/projects/${this.routeProjectExid}/media-hub/${this.clip.exid}`

      return this.isPending ? null : mediaUrl
    },
  },
  watch: {
    pendingClip: {
      immediate: true,
      async handler(pendingClip) {
        if (!pendingClip) {
          return
        }
        const currentTime = new Date().getTime()
        let clipCreatedTime = new Date(pendingClip.createdAt).getTime()
        const timeDifferenceInMilliseconds = Math.abs(
          currentTime - clipCreatedTime
        )
        const timeDifferenceInMinutes = Math.floor(
          timeDifferenceInMilliseconds / (1000 * 60)
        )

        if (timeDifferenceInMinutes < this.refreshTimeoutMinutes) {
          const pendingClipInterval = setInterval(
            () => this.getPendingMediaStatus(pendingClip, pendingClipInterval),
            this.refreshIntervalMs
          )
        }
      },
    },
  },
  methods: {
    getDate(clip: Media, date: DateType) {
      return this.$moment.tz(date, clip.cameraTimezone).format("L LT")
    },
    async getPendingMediaStatus(item: Media, pendingMediaItemInterval: number) {
      let clip = null as Media | null
      try {
        clip = await EvercamApi.mediaHub.cShow(
          this.routeProjectExid,
          item.exid as string
        )
        if (clip?.status === MediaStatus.Completed) {
          this.clip = clip
          this.$emit("refresh-media-hub")
          clearInterval(pendingMediaItemInterval)
        }
      } catch (error) {
        console.error(error)
      }
    },

    handleImageError(e) {
      console.error("error loading image", e)
      e.target.src = "/unavailable.jpg"
    },
    getHoverStyle(hover = false) {
      if (this.$vuetify.theme.dark) {
        return `lighten-${hover ? "2" : "1"}`
      }

      return `darken-${hover ? "2" : "1"}`
    },
    goToNextPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToNextPage)
    },
    goToPreviousPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToPreviousPage)
    },
  },
})
