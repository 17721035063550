
import Vue, { type PropType } from "vue"
import { formField } from "@evercam/ui"

export default Vue.extend({
  name: "CopilotFieldSelect",
  mixins: [formField],
  props: {
    items: {
      type: Array as PropType<
        { title: string; value: string; image: string }[]
      >,
      required: true,
    },
  },
})
