
import Vue from "vue"
export default Vue.extend({
  name: "IosMediaDialog",
  props: {
    srcUrl: {
      type: String,
      default: "",
    },
    isIosMp4Extension: {
      type: Boolean,
      default: false,
    },
    isIosExternalLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: true,
    }
  },
  computed: {
    setIosTitle(): string {
      if (this.isIosExternalLink) {
        return this.$t("title.long_press_ios_external_title")
      }
      if (this.isIosMp4Extension) {
        return this.$t("title.long_press_ios_mp4_title")
      }

      return this.$t("title.long_press")
    },
  },
})
