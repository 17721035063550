import axios from "@evercam/shared/api/client/axios"
import { toQueryString } from "@evercam/shared/utils"
import {
  HistoryWeatherRequestPayload,
  WeatherProvider,
  WeatherResponsePayload,
} from "@evercam/shared/types"

export const WeatherApi = {
  current: {
    getCurrentWeather({
      projectExid,
      weatherProvider = WeatherProvider.WeatherStack,
    }: {
      projectExid: string
      weatherProvider: WeatherProvider
    }): Promise<WeatherResponsePayload> {
      if (weatherProvider === WeatherProvider.WeatherStack) {
        return axios.get(
          `/projects/${projectExid}/weather/current?provider=weatherstack`
        )
      } else if (weatherProvider === WeatherProvider.AerisWeather) {
        return axios.get(
          `${axios.env.weatherApiBaseUrl}/weatherApi/getWeatherData?projectExid=${projectExid}`
        )
      }
    },
  },
  historical: {
    getWeatherBeforeDate({
      projectExid,
      weatherProvider = WeatherProvider.WeatherStack,
      payload,
    }: {
      projectExid: string
      weatherProvider: WeatherProvider
      payload: HistoryWeatherRequestPayload
    }): Promise<WeatherResponsePayload> {
      if (weatherProvider === WeatherProvider.WeatherStack) {
        return axios.get(
          `/projects/${projectExid}/weather?provider=weatherstack&${toQueryString(
            payload
          )}`
        )
      } else if (weatherProvider === WeatherProvider.AerisWeather) {
        return axios.get(
          `${axios.env.weatherApiBaseUrl}/weatherApi/getWeatherData?projectExid=${projectExid}&day=${payload.day}`
        )
      }
    },
  },
}
