import axios from "@evercam/shared/api/client/axios"
import {
  ExNvrDeviceConfig,
  ExNvrLoginResponse,
  ExNvrRecordingInterval,
} from "@evercam/shared/types/streaming"

export const ExNvrApi = {
  users: {
    login(
      { apiUrl, username, password },
      config = {}
    ): Promise<ExNvrLoginResponse> {
      return axios.post(
        `${apiUrl}/api/users/login`,
        {
          username,
          password,
        },
        config
      )
    },
  },
  devices: {
    getDevices({ apiUrl, token }): Promise<ExNvrDeviceConfig> {
      return axios.get(`${apiUrl}/api/devices/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    getDeviceConfig(
      { apiUrl, deviceId, token },
      config = {}
    ): Promise<ExNvrDeviceConfig> {
      return axios.get(`${apiUrl}/api/devices/${deviceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...config,
      })
    },
    getAvailableRecordings(
      { apiUrl, deviceId, token },
      config = {}
    ): Promise<ExNvrRecordingInterval[]> {
      return axios.get(`${apiUrl}/api/devices/${deviceId}/recordings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...config,
      })
    },
    downloadFootage({
      apiUrl,
      deviceId,
      token,
      startDate,
      duration,
    }): Promise<Blob> {
      return axios.get(`${apiUrl}/api/devices/${deviceId}/footage`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate,
          duration,
        },
        responseType: "blob",
      })
    },
  },
}
