import axios from "@evercam/shared/api/client/axios"
import { VideoWallPreset } from "@evercam/shared/types/videoWall"

export const VideoWallApi = {
  presets: {
    getProjectPresets(email, projectId) {
      const encodedEmail = btoa(email)

      return axios.get(
        `${axios.env.firebaseVideowallUrl}/${encodedEmail}/presets/${projectId}.json`,
        {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"]

            return data
          },
        }
      )
    },
    saveProjectPreset(email, projectId, payload) {
      const preset = JSON.stringify({ configuration: payload.configuration })
      const presetName = payload.name
      const encodedEmail = btoa(email)

      return axios.patch(
        `${axios.env.firebaseVideowallUrl}/${encodedEmail}/presets/${projectId}/${presetName}.json`,
        preset,
        {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"]

            return data
          },
        }
      )
    },
    getGlobalPreset(email): Promise<VideoWallPreset> {
      const encodedEmail = btoa(email)

      return axios.get(
        `${axios.env.firebaseVideowallUrl}/${encodedEmail}/presets/global.json`,
        {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"]

            return data
          },
        }
      )
    },
    saveGlobalPreset(email, payload: VideoWallPreset): Promise<void> {
      const preset = JSON.stringify({ configuration: payload.configuration })
      const encodedEmail = btoa(email)

      return axios.patch(
        `${axios.env.firebaseVideowallUrl}/${encodedEmail}/presets/global.json`,
        preset,
        {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"]

            return data
          },
        }
      )
    },
  },
}
