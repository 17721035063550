
import { mapStores } from "pinia"
import { useConfirmDialogStore } from "@evercam/shared/stores/confirmDialog"

export default {
  name: "ConfirmDialog",
  computed: {
    ...mapStores(useConfirmDialogStore),
    isAdmin() {
      return this.$config.public.appName === "admin"
    },
  },
}
