
import { batteryLevels } from "../constants/batteryReadings"

export default {
  name: "BatteryLevelIcon",
  props: {
    batteryVoltage: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  computed: {
    batteryLevel() {
      return batteryLevels.find(
        (item) =>
          this.batteryVoltage >= item.interval[0] &&
          this.batteryVoltage < item.interval[1]
      )?.level
    },
    batteryIcon() {
      return batteryLevels.find((item) => item.level === this.batteryLevel)
        ?.icon
    },
    batteryColor() {
      return batteryLevels.find((item) => item.level === this.batteryLevel)
        ?.color
    },
    batteryHasRecentReading() {
      return (
        typeof this.batteryVoltage === "number" && !isNaN(this.batteryVoltage)
      )
    },
  },
}
