
export default {
  name: "CtkDateTimePicker",
  inheritAttrs: false,
  props: {
    ctkdatetime: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "YYYY-MM-DDTHH:mm:ss.sss",
    },
  },
  data() {
    return {
      datetime: this.ctkdatetime,
    }
  },
}
