
import Vue, { type PropType } from "vue"
import type { ChatMessageAnnotation } from "@evercam/ui"

export default Vue.extend({
  name: "CopilotAnnotation",
  props: {
    annotation: {
      type: [Object, undefined] as PropType<ChatMessageAnnotation | undefined>,
      default: undefined,
    },
  },
})
