
import { CameraStatus } from "@evercam/shared/types/camera"
export default {
  name: "CameraStatusIcon",
  props: {
    camera: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    cameraStatuses() {
      return {
        [CameraStatus.Offline]: {
          icon: this.$device.isIos
            ? "fas fa-plug-circle-xmark"
            : "fa fa-unlink",
          color: `${this.$vuetify.theme.dark ? "#b22a20" : "#f43f5e"}`,
        },
        [CameraStatus.OfflineScheduled]: {
          icon: this.$device.isIos
            ? "fas fa-plug-circle-xmark"
            : "fa fa-unlink",
          color: `${this.$vuetify.theme.dark ? "#b22a20" : "#f43f5e"}`,
        },
        [CameraStatus.Waiting]: {
          icon: "far fa-clock",
          color: "",
        },
        [CameraStatus.UnderMaintenance]: {
          icon: "fa fa-wrench",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.WaitingForSiteVisit]: {
          icon: "fa fa-wrench",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.Decommissioned]: {
          icon: this.$device.isIos
            ? "fas fa-circle-check"
            : "fas fa-clipboard-check",
          color: "",
        },
        [CameraStatus.OnHold]: {
          icon: "far fa-pause-circle",
          color: "",
        },
      }
    },
    isCameraOnline() {
      return this.camera?.status === CameraStatus.Online
    },
    cameraStatus() {
      return this.cameraStatuses?.[this.camera?.status]
    },
    cameraStatusColor() {
      return this.cameraStatus?.color
    },
    cameraStatusIcon() {
      return this.cameraStatus?.icon
    },
  },
}
