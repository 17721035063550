import { GateReportVehicleType } from "@evercam/shared/types"

export const VEHICLE_TYPE_IDS = Object.values(GateReportVehicleType)

export const VEHICLE_TYPES = [
  { name: "Tipping truck", id: GateReportVehicleType.TippingTruck },
  { name: "Concrete truck", id: GateReportVehicleType.TruckMixer },
  { name: "Tank truck", id: GateReportVehicleType.TankTruck },
  { name: "Semi trailer", id: GateReportVehicleType.SemiTrailer },
  { name: "Truck head", id: GateReportVehicleType.TruckHead },
  {
    name: "Flatbed semi-trailer",
    id: GateReportVehicleType.FlatbedSemiTrailer,
  },
  { name: "Small truck", id: GateReportVehicleType.SmallTruck },
  { name: "Road vehicle", id: GateReportVehicleType.RoadVehicle },
  { name: "Concrete pump", id: GateReportVehicleType.ConcretePump },
  { name: "Other vehicles", id: GateReportVehicleType.OtherTruck },
  { name: "Unknown", id: GateReportVehicleType.Unknown },
]
