
import Vue, { type PropType } from "vue"
import {
  type CamerasByExid,
  type CopilotSystemToolCallResponse,
  CopilotToolId,
  type Media,
  type ProjectsByExid,
} from "@evercam/shared/types"
import CopilotToolCallResultMediaClip from "@evercam/shared/components/copilot/CopilotToolCallResultMediaClip.vue"
import { camelizeKeys } from "humps"
export default Vue.extend({
  name: "CopilotToolCallResult",
  components: {
    CopilotToolCallResultMediaClip,
  },
  props: {
    result: {
      type: Object as PropType<CopilotSystemToolCallResponse>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
  },
  computed: {
    isMediaResult(): boolean {
      return (
        this.result.toolId === CopilotToolId.CreateClip ||
        this.result.toolId === CopilotToolId.CreateTimelapse
      )
    },
    mediaClip(): Media {
      return camelizeKeys(this.result.output as Media) as Media
    },
  },
})
