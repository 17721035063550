
import schedules, {
  toNumericSchedule,
} from "@evercam/shared/constants/schedules"
import { TimelapseScheduleType } from "@evercam/shared/types/timelapse"
import Vue, { PropType } from "vue"

export default Vue.extend({
  name: "Schedule",
  props: {
    scheduleType: {
      type: String as PropType<TimelapseScheduleType>,
      default: "",
      required: true,
    },
    preloadSchedule: {
      type: String,
      default: "",
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      min: 0,
      max: 23,
      enabledDays: {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
        Sunday: true,
      },
      schedule: toNumericSchedule(schedules.CONTINUOUS),
      hours: [0, 23],
    }
  },
  computed: {
    isDisabled() {
      return (
        !this.editMode &&
        (this.scheduleType === TimelapseScheduleType.Continuous ||
          this.scheduleType === TimelapseScheduleType.WorkingHours)
      )
    },
  },
  watch: {
    scheduleType: {
      immediate: true,
      handler() {
        this.initEnabledDays()
        this.hours = Object.values(this.schedule)[1]
      },
    },
    schedule() {
      this.changeSchedule()
    },
  },
  mounted() {
    if (this.preloadSchedule) {
      let tempSchedule = JSON.parse(this.preloadSchedule)
      let loadSchedule = schedules.EMPTY
      Object.keys(tempSchedule).forEach((day) => {
        this.enabledDays[day] = !(tempSchedule[day].length === 0)
        if (tempSchedule[day].length !== 0) {
          loadSchedule[day][0] = parseInt(
            tempSchedule[day][0].split("-")[0].split(":")[0]
          )
          loadSchedule[day][1] = parseInt(
            tempSchedule[day][0].split("-")[1].split(":")[0]
          )
        } else {
          loadSchedule[day] = [0, 23]
        }
      })
      this.schedule = loadSchedule
    }
  },
  methods: {
    initEnabledDays() {
      const scheduleMap = {
        [TimelapseScheduleType.Continuous]: schedules.CONTINUOUS,
        [TimelapseScheduleType.Custom]: schedules.CONTINUOUS,
        [TimelapseScheduleType.WorkingHours]: schedules.WORKING_HOURS,
      }

      this.schedule = toNumericSchedule(scheduleMap[this.scheduleType])
      Object.keys(this.enabledDays).forEach((day) => {
        this.enabledDays[day] =
          this.scheduleType === TimelapseScheduleType.WorkingHours
            ? !["Saturday", "Sunday"].includes(day)
            : true
      })
    },
    toTextSchedule() {
      return Object.fromEntries(
        Object.entries(this.schedule as { [day: string]: string[] }).map(
          ([day, hours]) => {
            if (!hours?.length || !this.enabledDays[day]) {
              return [day, []]
            } else {
              const [startHour, endHour] = hours
              const first = `${startHour.toString().padStart(2, "0")}:00`
              const last = `${endHour.toString().padStart(2, "0")}:00`

              return [day, [`${first}-${last}`]]
            }
          }
        )
      )
    },
    changeSchedule() {
      this.$emit("input", this.toTextSchedule())
    },
    onHoursChange(newRange) {
      this.schedule = Object.entries(this.schedule).reduce((acc, [day]) => {
        return {
          ...acc,
          [day]: newRange,
        }
      }, {})
    },
  },
})
