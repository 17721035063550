
import Vue, { type PropType } from "vue"
import {
  type CameraExid,
  type CamerasByExid,
  type Media,
} from "@evercam/shared/types"
import MediaClip from "@evercam/shared/components/medias/MediaClip"

export default Vue.extend({
  name: "CopilotToolCallResultMediaClip",
  components: {
    MediaClip,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
    mediaClip: {
      type: Object as PropType<Media>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
  },
  computed: {
    cameraExid(): CameraExid | undefined {
      return this.mediaClip?.cameraExid
    },
    cameraName(): string {
      return (this.mediaClip?.cameraName ||
        this.camerasByExid[this.cameraExid!]?.name ||
        this.mediaClip.title?.split(" - ")?.[0]) as string
    },
    mediaUrl(): string {
      return `/v2/projects/${this.mediaClip.project_exid}/media-hub/${this.mediaClip.exid}`
    },
  },
})
