
import Vue from "vue"

export default Vue.extend({
  name: "CopilotHeaderActions",
  computed: {
    actions(): Array<{
      name: string
      tooltip: string
      icon: string
      handler: () => void
    }> {
      return [
        {
          name: "collapse",
          tooltip: "Collapse",
          icon: "chevron-down",
          handler: this.emitCollapseChange,
        },
        {
          name: "expand",
          tooltip: "Expand",
          icon: "chevron-up",
          handler: this.emitCollapseChange,
        },
      ]
    },
  },
  methods: {
    emitCollapseChange() {
      this.$emit("toggle-collapsed")
    },
  },
})
