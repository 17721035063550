
import { weatherIcons } from "@evercam/shared/icons/weatherIcons"

export default {
  name: "WeatherIcon",
  props: {
    index: {
      type: Number,
      default: null,
    },
    size: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    src() {
      const iconName = weatherIcons[this.index]?.slug

      return iconName
        ? require(`@evercam/shared/assets/img/icons/weather/animated/${iconName}.svg`)
        : ""
    },
  },
}
